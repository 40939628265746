"use strict";
require.config({
    baseUrl: "/resources/𝓥𝓔𝓡𝓢𝓘𝓞𝓝/",
    paths: {
        "hypo_bvo": "_assets/hypo-bvo/JavaScript/Bvo",
        "hypo_elements": "_assets/hypo-elements/JavaScript",
        "@drklein/client-detection": "vendor/@drklein/client-detection/dist/index",
        "@drklein/touch-detection": "vendor/@drklein/client-detection/dist/touch-detection",
        "@drklein/mobile-detection": "vendor/@drklein/client-detection/dist/mobile-detection",
        "@drklein/browser-detection": "vendor/@drklein/client-detection/dist/browser-detection",
        "@drklein/form-element": "vendor/@drklein/form-element",
        "@drklein/locale-formatter": "vendor/@drklein/locale-formatter/dist/locale-formatter",
        // External dependencies
        "@sentry/browser": "vendor/@sentry/browser/build/bundle.min",
        "async": "vendor/requirejs-plugins/src/async",
        "jquery": "vendor/jquery/dist/jquery.min",
        "jquery-ui": "vendor/jquery-ui/jquery-ui.min",
        "jquery-ui/ui": "vendor/jquery-ui/ui",
        "bootstrap": "vendor/bootstrap-sass/assets/javascripts/bootstrap.min",
        "mustache": "vendor/mustache/mustache.min",
        "hammerjs": "vendor/hammerjs/hammer.min",
        "iframe-resizer": "vendor/iframe-resizer/js/iframeResizer.min",
        "js-cookie": "vendor/js-cookie/src/js.cookie",
        "jquery-countto": "vendor/jquery-countto/jquery.countTo",
        "jquery-waypoints": "vendor/waypoints/lib/jquery.waypoints.min",
        "angular": "vendor/angular/angular.min",
        "angular-route": "vendor/angular-route/angular-route.min",
        "angular-i18n": "vendor/angular-i18n/angular-locale_de",
        "angular-chart": "vendor/angular-chart.js/dist/angular-chart.min",
        "chart": "vendor/chart.js/dist/Chart.min",
        "chartjs-4": "vendor/chartjs-4/dist/chart.umd",
        "chartjs-adapter-moment-fix": "_assets/hypo-elements/Vendor/chartjs-adapter-moment-fix/chartjs-adapter-moment.min",
        "devextreme": "vendor/devextreme/dist/js/dx.all",
        "moment": "vendor/moment/min/moment.min",
        "moment-fix": "_assets/hypo-elements/JavaScript/Typescripts/Main/MomentFix",
        "webfontloader": "vendor/webfontloader/webfontloader",
        "word-counter": "_assets/hypo-elements/JavaScript/Typescripts/WordCounter/WordCounter",
        "social-share": "_assets/hypo-elements/JavaScript/Elements/SocialShare/SocialShare",
        "libphonenumber-js": "vendor/libphonenumber-js/bundle/libphonenumber-js.min",
        "leadid-reader": "_assets/hypo-elements/JavaScript/Elements/LeadidReader/LeadidReader",
        "swiper": "vendor/swiper/swiper-bundle.min",
        "glightbox": "vendor/glightbox/dist/js/glightbox.min",
        "lazysizes": "vendor/lazysizes/lazysizes.min",
        // AngularJS apps
        "zinstableauApp": "apps/zinstableau/js/zinstableau.min",
        "zinschartApp": "apps/zinschart/js/zinschart.min",
        "bauzinsenrechnerApp": "apps/bauzinsenrechner/js/bauzinsenrechner.min",
        "ratenkreditrechnerApp": "apps/ratenkreditrechner/js/ratenkreditrechner.min",
        "kreditrechner": "apps/drklein/kreditrechner/drk-kreditrechner",
        "baufinanzierungAntragDrklein": "apps/baufinanzierung-antrag-drklein",
        "beratungsfeedbackNeuModule": "apps/beratungsfeedback/beratungsfeedback-es2015",
        "beratungsfeedbackNeuNoModule": "apps/beratungsfeedback/beratungsfeedback-es5",
        "grundbuchrechnerNeuModule": "apps/drk-notar-grundbuchkosten-rechner/drk-notar-grundbuchkosten-rechner-es2015",
        "grundbuchrechnerNeuNoModule": "apps/drk-notar-grundbuchkosten-rechner/drk-notar-grundbuchkosten-rechner-es5",
        "mietkaufrechnerapp": "apps/miet-kauf-rechner-2020/bootstrap-angular-app",
        // Formi apps
        "formi-fix": "_assets/hypo-elements/JavaScript/Typescripts/Main/FormiFix"
    },
    map: {
        "*": {
            "configuration": "hypo_elements/Typescripts/Configuration/Configuration",
            "devextreme/dist/ts/dx.all": "vendor/devextreme/dist/js/dx.viz"
        },
    },
    shim: {
        "@sentry/browser": {
            exports: "Sentry",
        },
        "jquery-waypoints": {
            deps: ["jquery"],
            exports: "window.Waypoint",
        },
        "@drklein/form-element/form-element": {
            deps: [
                "jquery-ui",
                "jquery-ui/ui/i18n/datepicker-de",
            ],
        },
        "hypo_elements/Typescripts/GoogleMaps/GoogleMaps": {
            deps: ["async!https://maps.googleapis.com/maps/api/js?key=AIzaSyC3ZnXelFngN0fB1-A-OWd-TWlDwjJCMxU&language=de"],
        },
        "vendor/jquery-ui/ui/i18n/datepicker-de": {
            deps: [
                "jquery-ui/ui/widgets/datepicker",
            ],
        },
        "bootstrap": {
            deps: ["jquery"],
            exports: "$.fn.modal",
        },
        "angular": {
            exports: "window.angular",
        },
        "angular-route": {
            deps: ["angular"],
        },
        "angular-i18n": {
            deps: ["angular"],
        },
        "chart": {
            deps: ["moment-fix"],
            exports: "window.Chart",
        },
        "angular-chart": {
            deps: [
                "angular",
                "chart",
            ],
        },
        "zinstableauApp": {
            deps: [
                "angular",
                "angular-route",
                "angular-i18n",
            ],
        },
        "zinschartApp": {
            deps: [
                "angular",
                "angular-route",
                "angular-i18n",
                "angular-chart",
            ],
        },
        "bauzinsenrechnerApp": {
            deps: [
                "angular",
                "angular-route",
                "angular-i18n",
                "angular-chart",
                "jquery",
            ],
        },
        "ratenkreditrechnerApp": {
            deps: [
                "angular",
                "angular-route",
                "angular-i18n",
            ],
        },
        "baufinanzierungAntragDrklein/polyfills": {
            deps: ["baufinanzierungAntragDrklein/runtime"],
        },
        "baufinanzierungAntragDrklein/main": {
            deps: ["baufinanzierungAntragDrklein/polyfills"],
        },
        "/apps/formi/baufidrk01/formi.min.js": {
            deps: [
                "formi-fix",
                "moment-fix",
            ],
        },
        "/apps/formi/baufidrk02/formi.min.js": {
            deps: [
                "formi-fix",
                "moment-fix",
            ],
        },
        "/apps/formi/ratenkreditdrk01/formi.min.js": {
            deps: [
                "formi-fix",
                "moment-fix",
            ],
        },
    },
    waitSeconds: 0,
    enforceDefine: false,
});
require(['jquery'], function ($) {
    require(['hypo_elements/Typescripts/require-initial-dependencies-built']);
});
